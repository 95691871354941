import React from 'react';
import { Link } from 'react-router-dom';

const ArticlePreview = ({ id, title, preview, imageUrl }) => {
    const imageExtension = imageUrl.split('.').pop();
    const imageBase = imageUrl.replace(`.${imageExtension}`, '');
    const smallImageUrl = `${imageBase}-small.${imageExtension}`;

    return (
        <Link className="article-preview-link" to={`/learn/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className='article-preview'>
                <div className='article-preview-image-container'>
                    <img className="article-preview-image" src={smallImageUrl} alt={title} loading="lazy"/>
                </div>
                <div className='article-preview-text-container'>
                    <h2 className='article-preview-title'>{title}</h2>
                    <p className='article-preview-text'>{preview}</p>
                </div>
                
            </div>
        </Link>
    )
}

export default ArticlePreview;
