import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CalculateTab from './CalculateTab';
import CalculateLandingPage from './calculate/CalculateLanding';
import ResearchLandingPage from './research/ResearchLandingPage';
import Learn from './learn/Learn.js';
import HomePage from './HomePage';
import ScrollToTop from './ScrollToTop';
import BottomNav from './BottomNav';
import Footer from './Footer.js'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className='App'>
        <BottomNav />
        <div className='app-container'>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/calculate" element={<CalculateLandingPage />} />
            <Route path="/calculate/*" element={<CalculateTab />} />
            <Route path="/research/*" element={<ResearchLandingPage />} />
            <Route path="/learn/*" element={<Learn />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
