import React, { useState } from "react";

const CustomSelect = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
        props.onOptionClicked(value);
    };

    return (
        <div className="dd-wrapper">
            <div tabIndex={0} className="dd-header" role="button" onKeyPress={() => toggling(!isOpen)} onClick={() => toggling(!isOpen)}>
            <div className="dd-header__title">
                <p className={selectedOption ? "dd-header__title--selected" : "dd-header__title--unselected"}>
                    {selectedOption || "Refine"}
                </p>
            </div>
            </div>
            {isOpen && (
                <ul className="dd-list">
                    {props.options.map((option) => (
                        <li className="dd-list-item" key={option} onClick={onOptionClicked(option)}>
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomSelect;
