// src/components/HomePage.js or src/pages/HomePage.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import articles from './data/articles.json';


function HomePage() {
    const [featuredArticles, setFeaturedArticles] = useState([]);

    useEffect(() => {
      // Filter for featured articles
      const fetchedArticles = articles.filter(article => article.featured);
      setFeaturedArticles(fetchedArticles);
    }, []);
  return (
    <div className="homepage">
    <div className='content-container'>
        <div className='homepage-tile-container'>

        <section className='homepage-header-section'>
        <div>
      <header className="homepage-header">
      <h1>Welcome to Cool{'\u00A0'}TVM</h1>

      </header>
      </div>
      </section>

      <section className="homepage-section homepage-section--3x-wide">
        <div className="section-content">
          <h2 className='homepage-section-title'>Featured Articles</h2>
            <div className='featured-article-container'>
          {/* Display the featured articles */}
          {featuredArticles.map((article) => (
            <Link className='featured-article' to={`/learn/${article.id}`} key={article.id}>
                <div>
                <h3 className='homepage-article-title'>{article.title}</h3>
                <img className='homepage-article-image' src={article.imageUrl} alt={article.title} />
                </div>
            </Link>
            ))}

          </div>
        </div>
      </section>

      <section className="homepage-section homepage-section--wide">
        <div className="section-content section-content--flex">
            <div className='homepage-section-wording'>
                <h2 className='homepage-section-title'>Easy calculations</h2>
                <p>With our tool, you can easily calculate the future value of your investments. Explore various scenarios and optimize your investment strategy.</p>
            </div>
            <div>
            <Link to="/calculate">
              <button className="cta-button">Start Calculating</button>
            </Link>
            </div>
        </div>
      </section>

      <section className="homepage-section">
        <div className="section-content section-content--flex">
        <div className='homepage-section-wording'>
          <h2 className='homepage-section-title'>Learn</h2>
          <p>Use our learning resources to deepen your understanding of investment concepts.</p>
          </div>
          <div>
              <Link to="/learn">
                <button className="cta-button">View Articles</button>
              </Link>
              </div>
        </div>
      </section>


      </div>

    </div>
    </div>
  );
}

export default HomePage;
