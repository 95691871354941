import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import articles from '../data/articles.json';

const Article = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState(null);

  const getArticleById = id => {
    return articles.find(article => article.id === id);
  }

  useEffect(() => {
    const fetchedArticle = getArticleById(articleId);
    setArticle(fetchedArticle);
  }, [articleId]);

  if (!article) {
    return <p>Loading...</p>
  }

  return (
    <div className='article-container'>
        <div className='article-heading-container'>
        <h1 className="article-title">{article.title}</h1>
         <div className="article-image-container">
        <img className="article-image" src={article.imageUrl} alt={article.title} />
        </div>
        </div>
        <div className='paragraphs-container'>
            {article.content.map((paragraph, index) => (
                <p className="article-paragraph" key={index}>{paragraph}</p>
            ))}
        </div>
    </div>
  );
}

export default Article;
