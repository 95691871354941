import React from 'react';

const FVInputs = ({ timeframe, setTimeframe, interestRate, setInterestRate, initialValue, setInitialValue, recurringDeposit, setRecurringDeposit, depositPeriod, setDepositPeriod, contributionPeriod, setContributionPeriod }) => {

  const handleTimeframeChange = e => {
    let timeframeValue = e.target.value;
    if (timeframeValue === '') {
      setTimeframe('');
    } else if (Number(timeframeValue).toString().length <= 3) {
      setTimeframe(Number(timeframeValue));
    }
  };
  const handleInterestRateChange = e => {
    let interestRateValue = e.target.value;
    if (interestRateValue === '') {
      setInterestRate('');
    } else if (Number(interestRateValue).toString().length <= 3) {
      setInterestRate(Number(interestRateValue));
    }
  };


  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/\d/.test(keyValue) && event.key !== '.' && event.key !== '-')
        event.preventDefault();
  };

  return (
  <div className="inputs-container">
      <div className="input-container">
          <label htmlFor="timeframe" className="input-label">Timeframe (Years)</label>
          <input id="timeframe" type="number" step="1" value={timeframe} onChange={handleTimeframeChange} onKeyPress={handleKeyPress} className="input-field" onBlur={() => window.scrollBy(0,0.1)} />
      </div>
      <div className="input-container">
          <label htmlFor="interestRate" className="input-label">Annual Return (%)</label>
          <input id="interestRate" type="number" step="0.01" value={interestRate} onChange={handleInterestRateChange} className="input-field" onBlur={() => window.scrollBy(0,0.1)}/>
      </div>
      <div className="input-container">
          <label htmlFor="initialValue" className="input-label">Initial Value ($)</label>
          <input id="initialValue" type="number" value={initialValue} onChange={e => setInitialValue(e.target.value === '' ? '' : Number(e.target.value))} onKeyPress={handleKeyPress} className="input-field" onBlur={() => window.scrollBy(0,0.1)}/>
      </div>
      <div className="input-container">
        <label htmlFor="recurringDeposit" className="input-label">Contribution ($)</label>
        <input id="recurringDeposit" type="number" value={recurringDeposit === 0 ? '' : recurringDeposit} onChange={e => setRecurringDeposit(e.target.value === '' ? 0 : Number(e.target.value))} onKeyPress={handleKeyPress} className="input-field" onBlur={() => window.scrollBy(0,0.1)}/>
      </div>
      
      <div className="input-container">
      <div className="contribution-container">
        <button className={contributionPeriod === 'Annual' ? 'selected' : ''} onClick={() => setContributionPeriod('Annual')}>Annual</button>
        <button className={contributionPeriod === 'Monthly' ? 'selected' : ''} onClick={() => setContributionPeriod('Monthly')}>Monthly</button>
        <button className={contributionPeriod === 'Weekly' ? 'selected' : ''} onClick={() => setContributionPeriod('Weekly')}>Weekly</button>
        <button className={contributionPeriod === 'Daily' ? 'selected' : ''} onClick={() => setContributionPeriod('Daily')}>Daily</button>
      </div>
    </div>

      <div style={{display: 'none'}} className="input-container">
          <label htmlFor="depositPeriod" className="input-label">Deposit Period</label>
          <select id="depositPeriod" value={depositPeriod} onChange={e => setDepositPeriod(e.target.value)} className="input-field">
          <option value="Beginning">Beginning</option>
          <option value="End">End</option>
          </select>
      </div>
  </div>
  );
};

export default FVInputs;
