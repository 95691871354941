import React from 'react';
import { NavLink, Routes, Route } from 'react-router-dom';
import FutureValueCalculator from './calculate/FutureValueCalculator';
import RetirementCalculator from './calculate/retirement/RetirementCalculator';


function CalculateTab() {
  return (
    <div className='content-container'>
      <nav className='fv-pv-tabs'>
        <ul>
          <li>
            <NavLink to="../future-value" activeClassName="active">Future Value</NavLink>
          </li>
          <li>
            <NavLink to="../retirement" activeClassName="active">Retirement Calculator</NavLink>
          </li>
        </ul>
      </nav>
      <Routes>
        <Route path="future-value" element={<FutureValueCalculator />} />
        <Route path="retirement-calculator" element={<RetirementCalculator />} />

      </Routes>
    </div>
  );
}

export default CalculateTab;
