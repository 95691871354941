import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames'; // you need to install this package

function BottomNav() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const bottomNavContainerClass = classNames({
    'bottom-nav-container': true,
    'bottom-nav-container--dark': isHomePage,
  });

  return (
    <div className={bottomNavContainerClass}>
      <nav className='bottom-nav'>
        <ul>
          <li>
            <NavLink className="nav-link" to="/" activeClassName="active">Home</NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to="/calculate" activeClassName="active">Calculate</NavLink>
          </li>
          <li>
            <NavLink className="nav-link" to="/learn" activeClassName="active">Learn</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default BottomNav;
