import React, { useState, useEffect } from 'react';
import FutureValueChart from './FutureValueChart';
import FVInputs from './FVInputs';

function TVMCalculator() {
  const [timeframe, setTimeframe] = useState(10);
  const [interestRate, setInterestRate] = useState(8);
  const [initialValue, setInitialValue] = useState(1000);
  const [recurringDeposit, setRecurringDeposit] = useState(200);
  const [depositPeriod, setDepositPeriod] = useState("End");
  const [contributionPeriod, setContributionPeriod] = useState("Monthly");
  const [futureValue, setFutureValue] = useState(0);
  const [futureValues, setFutureValues] = useState([]);
  const [investmentData, setInvestmentData] = useState([]);  // New state for investment data

  useEffect(() => {
    calculateFutureValue();
  }, [timeframe, interestRate, initialValue, recurringDeposit, depositPeriod, contributionPeriod]);

  const calculateFutureValue = () => {
    let periodMultiplier;
    let periodInterestRate;
  
    switch (contributionPeriod) {
      case "Annual":
        periodMultiplier = 1;
        periodInterestRate = interestRate ? (interestRate / 100) + 1 : 1;
        break;
      case "Monthly":
        periodMultiplier = 12;
        periodInterestRate = interestRate ? ((interestRate / 100) / 12) + 1 : 1;
        break;
      case "Weekly":
        periodMultiplier = 52;
        periodInterestRate = interestRate ? ((interestRate / 100) / 52) + 1 : 1;
        break;
      case "Daily":
        periodMultiplier = 365;
        periodInterestRate = interestRate ? ((interestRate / 100) / 365) + 1 : 1;
        break;
      default:
        break;
    }
  
  
    if ((timeframe || 0) !== '' && (!Number.isInteger(timeframe || 0) || (timeframe || 0) < 0)) {
      alert("Timeframe must be a positive integer.");
      return;
    }
  
    let futureValue = initialValue || 0;
    let totalInvestment = initialValue || 0;
    let futureValues = [];
    let investmentData = [];
  
    for (let year = 1; year <= (timeframe || 0); year++) {
      totalInvestment += (recurringDeposit || 0) * periodMultiplier;
      if (interestRate && interestRate > 0) {
        futureValue = (initialValue || 0) * Math.pow(periodInterestRate, year * periodMultiplier)
                    + (recurringDeposit || 0) * (Math.pow(periodInterestRate, year * periodMultiplier) - 1) / (periodInterestRate - 1);
      } else {
        futureValue = totalInvestment;
      }
  
      futureValues.push(futureValue);
      investmentData.push({
        totalInvestment,
        return: futureValue - totalInvestment
      });
    }
  
    setFutureValue(futureValue);
    setFutureValues(futureValues);
    setInvestmentData(investmentData);
  };
  
  
  
  
  

  return (
    <div className="chart-and-input-container">
      <div className="chart-container">
      <p className="future-value-title">Future Value</p>
      <p className='future-value'>{Number(futureValue.toFixed(2)).toLocaleString('en-US')}</p>
        <FutureValueChart
          data={futureValues}
          investmentData={investmentData}  // Pass investment data to the chart
          initialValue={initialValue}
          recurringDeposit={recurringDeposit}
          contributionPeriod={contributionPeriod}
          timeframe={timeframe}
        />
      </div>
      <FVInputs 
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        interestRate={interestRate}
        setInterestRate={setInterestRate}
        initialValue={initialValue}
        setInitialValue={setInitialValue}
        recurringDeposit={recurringDeposit}
        setRecurringDeposit={setRecurringDeposit}
        depositPeriod={depositPeriod}
        setDepositPeriod={setDepositPeriod}
        contributionPeriod={contributionPeriod}
        setContributionPeriod={setContributionPeriod}
      />
    </div>
  );
}

export default TVMCalculator;
