import React, { useState, useEffect } from 'react';

const RetirementCalculator = () => {
  const LIFESPAN = 100;

  const [currentAge, setCurrentAge] = useState(30);
  const [retirementAge, setRetirementAge] = useState(65);
  const [currentIncome, setCurrentIncome] = useState(80000);
  const [currentSavings, setCurrentSavings] = useState(50000);
  const [annualSavings, setAnnualSavings] = useState(5000);
  const [rateOfReturn, setRateOfReturn] = useState(7);
  const [inflationRate, setInflationRate] = useState(2.9);
  const [incomePercentage, setIncomePercentage] = useState(70);
  const [socialSecurityStartAge, setSocialSecurityStartAge] = useState(67);
  const [estimatedSocialSecurity, setEstimatedSocialSecurity] = useState(1500);

  const calculateFutureValues = () => {
    // Convert rates to decimal form
    const returnRateDecimal = rateOfReturn / 100;
    const inflationRateDecimal = inflationRate / 100;

    // Calculate the number of years until retirement
    const yearsUntilRetirement = retirementAge - currentAge;

    // Calculate the future value of current savings
    const futureValueOfCurrentSavings = currentSavings * Math.pow((1 + returnRateDecimal), yearsUntilRetirement);

    // Calculate the future value of annual savings (this assumes end-of-year contributions)
    let futureValueOfAnnualSavings = 0;
    for(let i = 0; i < yearsUntilRetirement; i++) {
      futureValueOfAnnualSavings = (futureValueOfAnnualSavings + annualSavings) * (1 + returnRateDecimal);
    }

    // Calculate total social security income
    let totalSocialSecurityIncome = 0;
    if (socialSecurityStartAge > retirementAge) {
      const yearsOfSocialSecurity = LIFESPAN - socialSecurityStartAge;
      totalSocialSecurityIncome = estimatedSocialSecurity * 12 * yearsOfSocialSecurity;
    }

    return {
      futureValueOfCurrentSavings,
      futureValueOfAnnualSavings,
      totalSocialSecurityIncome
    };
  };

  const calculateRetirementGoals = (totalSocialSecurityIncome) => {
    // Convert rates to decimal form
    const inflationRateDecimal = inflationRate / 100;

    // Calculate the number of years until retirement
    const yearsUntilRetirement = retirementAge - currentAge;

    // Calculate the desired retirement income in today's dollars
    const desiredIncomeToday = (currentIncome * incomePercentage) / 100;

    // Adjust this number for inflation to get the desired retirement income in future dollars
    const desiredIncomeFuture = desiredIncomeToday * Math.pow((1 + inflationRateDecimal), yearsUntilRetirement);

    // Subtract total Social Security income from the total savings needed
    const totalSavingsNeeded = desiredIncomeFuture * 25 - totalSocialSecurityIncome;

    return {
      desiredIncomeToday,
      desiredIncomeFuture,
      totalSavingsNeeded
    };
  };

  const initialFutureValues = calculateFutureValues();
  const initialRetirementGoals = calculateRetirementGoals(initialFutureValues.totalSocialSecurityIncome);
  const [results, setResults] = useState({ ...initialFutureValues, ...initialRetirementGoals });

  useEffect(() => {
    const futureValues = calculateFutureValues();
    const retirementGoals = calculateRetirementGoals(futureValues.totalSocialSecurityIncome);
    setResults({ ...futureValues, ...retirementGoals });
  }, [currentAge, retirementAge, currentIncome, currentSavings, annualSavings, rateOfReturn, inflationRate, incomePercentage, socialSecurityStartAge, estimatedSocialSecurity]);

  const formatCurrency = (value) => {
    return value ? Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "$0.00";
  };

  return (
    <div>
        <h1>Work in progress</h1>
      <div className="input-container">
        <label>Current Age</label>
        <input type="number" value={currentAge} onChange={e => setCurrentAge(e.target.value)} />
      </div>

      <div className="input-container">
        <label>Retirement Age</label>
        <input type="number" value={retirementAge} onChange={e => setRetirementAge(e.target.value)} />
      </div>

      <div className="input-container">
        <label>Current Income</label>
        <input type="number" value={currentIncome} onChange={e => setCurrentIncome(e.target.value)} />
      </div>

      <div className="input-container">
        <label>Current Savings</label>
        <input type="number" value={currentSavings} onChange={e => setCurrentSavings(e.target.value)} />
      </div>

      <div className="input-container">
        <label>Annual Savings</label>
        <input type="number" value={annualSavings} onChange={e => setAnnualSavings(e.target.value)} />
      </div>

      <div className="input-container">
        <label>Annual Rate of Return</label>
        <input type="number" value={rateOfReturn} onChange={e => setRateOfReturn(e.target.value)} />
      </div>

      <div className="input-container">
        <label>Annual Inflation Rate</label>
        <input type="number" value={inflationRate} onChange={e => setInflationRate(e.target.value)} />
      </div>

      <div className="input-container">
        <label>Income Replacement Percentage</label>
        <input type="number" value={incomePercentage} onChange={e => setIncomePercentage(e.target.value)} />
      </div>

      <div className="input-container">
        <label>Social Security Start Age</label>
        <input type="number" value={socialSecurityStartAge} onChange={e => setSocialSecurityStartAge(e.target.value)} />
      </div>

      <div className="input-container">
        <label>Estimated Monthly Social Security</label>
        <input type="number" value={estimatedSocialSecurity} onChange={e => setEstimatedSocialSecurity(e.target.value)} />
      </div>

      <h2>Results</h2>
      <p>Future value of current savings: {formatCurrency(results.futureValueOfCurrentSavings)}</p>
      <p>Future value of annual savings: {formatCurrency(results.futureValueOfAnnualSavings)}</p>
      <p>Desired retirement income in today's dollars: {formatCurrency(results.desiredIncomeToday)}</p>
      <p>Desired retirement income in future dollars: {formatCurrency(results.desiredIncomeFuture)}</p>
      <p>Total retirement savings needed: {formatCurrency(results.totalSavingsNeeded)}</p>
      <p>Safe Withdrawal Rate (4% rule): {formatCurrency(results.totalSavingsNeeded * 0.04)}</p>
    </div>
  );
};

export default RetirementCalculator;
