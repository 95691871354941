import React, { useState } from 'react';

const ResearchLandingPage = () => {
  const [investmentSymbol, setInvestmentSymbol] = useState("");
  const [investmentData, setInvestmentData] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  const fetchInvestmentData = async (symbol) => {
    // Set investmentData to null before making a new API call
    setInvestmentData(null);
  
    const response = await fetch(`https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${symbol}&apikey=M982339MUNR1DS44`);
    const data = await response.json();
    setInvestmentData(data["Global Quote"]);
  };
  

  const handleSymbolChange = async (e) => {
    setInvestmentSymbol(e.target.value);
    const response = await fetch(`https://www.alphavantage.co/query?function=SYMBOL_SEARCH&keywords=${e.target.value}&apikey=M982339MUNR1DS44`);
    const data = await response.json();
    setSearchResults(data.bestMatches || []);
  };

  const handleDropdownSelect = (symbol) => {
    setInvestmentSymbol(symbol);
    fetchInvestmentData(symbol);
    setSearchResults([]);
  };

  return (
    <div className='content-container'>
      <h2>Lookup</h2>
      <div className="input-container">
        <label>Investment Symbol</label>
        <input type="text" value={investmentSymbol} onChange={handleSymbolChange} />
        {searchResults.length > 0 && (
          <div className="dropdown">
            {searchResults.map((result, index) => (
              <div key={index} onClick={() => handleDropdownSelect(result['1. symbol'])}>
                {result['1. symbol']} - {result['2. name']}
              </div>
            ))}
          </div>
        )}
      </div>
      {investmentData && (
        <div>
          <p>Symbol: {investmentData["01. symbol"]}</p>
          <p>Open: {investmentData["02. open"]}</p>
          <p>High: {investmentData["03. high"]}</p>
          <p>Low: {investmentData["04. low"]}</p>
          <p>Price: {investmentData["05. price"]}</p>
          <p>Volume: {investmentData["06. volume"]}</p>
          <p>Latest Trading Day: {investmentData["07. latest trading day"]}</p>
          <p>Previous Close: {investmentData["08. previous close"]}</p>
          <p>Change: {investmentData["09. change"]}</p>
          <p>Change Percent: {investmentData["10. change percent"]}</p>
        </div>
      )}
    </div>
  );
};

export default ResearchLandingPage;
