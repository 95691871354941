import React from 'react';
import { Link } from 'react-router-dom';

const CalculateLandingPage = () => {
  return (
    <div className='content-container'>
        <div className='homepage-tile-container'>

        <section className='homepage-header-section'>
        <div>
      <header className="homepage-header">
      <h1>Calculators</h1>
      <p>Visualize Your Financial Future</p>
      </header>

      </div>
      </section>

      <section className="homepage-section homepage-section--future-value">
        <div className="section-content section-content--flex">
            <div className='homepage-section-wording'>
                <h2 className='homepage-section-title'>Future Value Calculator</h2>
                <p>Calculate the future value of your investments.</p>
            </div>
            <div>
          <Link to="/calculate/future-value">
            <button className="cta-button">Start Calculating</button>
          </Link>
          </div>
        </div>
      </section>


      <section className="homepage-section">
        <div className="section-content section-content--flex">
            <div className='homepage-section-wording'>
                <h2 className='homepage-section-title'>Retirement Calculator</h2>
                <p>Estimate your retirement needs and see if you're on track.</p>
            </div>
            <div>
          <Link to="/calculate/retirement-calculator">
            <button className="cta-button">Start Calculating</button>
          </Link>
          </div>
        </div>
      </section>
      </div>
      </div>
  );
};

export default CalculateLandingPage;
