import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Article from './Article';
import ArticlePreview from './ArticlePreview';
import articles from '../data/articles.json';
import CustomSelect from './CustomSelect';


const Learn = () => {
    const [selectedTag, setSelectedTag] = useState("");

    const handleTagChange = (value) => {
        setSelectedTag(value === 'All' ? '' : value);
    }

    const getUniqueTags = () => {
        const tags = articles.flatMap(article => article.tags);
        return [...new Set(tags)];
    }

    const filteredArticles = selectedTag ? articles.filter(article => article.tags.includes(selectedTag)) : articles;

    return (
        <div className='content-container'>
            <Routes>
                <Route path="/" element={
                    <div>
                        <div className='learn-heading-container'>
                            <h1 className="learn-heading">Learn</h1>
                            <div className='refine-container'>
                                <CustomSelect options={['All', ...getUniqueTags()]} onOptionClicked={handleTagChange} />
                            </div>
                        </div>
                        <div key={selectedTag} className="articles-container">
                            {filteredArticles.map(article => <ArticlePreview key={article.id} {...article} />)}
                        </div>
                    </div>
                } />
                <Route path=":articleId" element={<Article />} />
            </Routes>
        </div>
    )
}

export default Learn;
