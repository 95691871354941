// src/components/Footer.js

import React from 'react';
import disclaimer from './data/disclaimer.json';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="homepage-footer">
      <div className='content-container content-container--footer'>
        {disclaimer.disclaimer.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      <div className='footer-bottom'>
        <p>© {currentYear} Cool TVM. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
