// FutureValueChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const FutureValueChart = ({ data, investmentData }) => {
    // Get current year
    const currentYear = new Date().getFullYear();

    // Define labels based on data length
    const labels = data && data.map((_, index) => `${currentYear + index}`);

    const investmentDataValues = investmentData && investmentData.map(({ totalInvestment }) => totalInvestment);
    const returnDataValues = investmentData && investmentData.map(({ return: returnVal }) => returnVal);

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Investment',
                data: investmentDataValues,
                backgroundColor: '#91b8f9',
            },
            {
                label: 'Return',
                data: returnDataValues,
                backgroundColor: '#d1f2ff',
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                stacked: true,
                grid: {
                    color: 'white',
                },
                ticks: {
                    color: 'white',
                },
            },
            x: {
                grid: {
                    drawOnChartArea: false,
                    color: 'white',
                },
                stacked: true,
                ticks: {
                    color: 'white',
                },
            },
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: 'white',
                },
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                position: 'average',
                padding: 16,
                animation: {
                    duration: 200, // Duration in milliseconds
                },
                titleFont: {
                    size: 21, // change title font size
                    lineHeight: 1.2,
                    color: '#222',
                },
                titleColor: '#222',
                bodyFont: {
                    size: 15, // change body font size
                    lineHeight: 1.4, // change body line spacing
                },
                bodyColor: '#222',
                backgroundColor: '#ffffff',
                itemSort: (a, b) => a.datasetIndex - b.datasetIndex,
                callbacks: {
                    title: function (context) {
                        return context[0].label;
                    },
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                        }
                        return label;
                    }
                },
            }
        },
        elements: {
            bar: {
                borderRadius: 4,
            },
        },
    };

    // Custom plugin to force tooltip to always be on top of the current bar
    const plugin = {
        id: 'custom',
        beforeTooltipDraw: (chart) => {
            const tooltipModel = chart.tooltip;
            if (tooltipModel.dataPoints.length > 0) {
                const firstPoint = chart.getDatasetMeta(0).data[tooltipModel.dataPoints[0].dataIndex];
                tooltipModel.caretY = firstPoint.y;
            }
        },
    };

    return (
        <div className="chart">
            <Bar data={chartData} options={options} plugins={[plugin]} />
        </div>
    );
};

export default FutureValueChart;
